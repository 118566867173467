import './App.css';

function App() {
  return (
    <div className="App">
      <p>This is a test</p>
    </div>
  );
}

export default App;
